




































import { Component, Prop, Vue } from 'vue-property-decorator'
import { minLength, required } from 'vuelidate/lib/validators'
import FormGroup from '@/components/forms/FormGroup.vue'
import Field from '@/components/forms/Field.vue'
import BaseSelectNew from '@/components/forms/BaseSelectNew/BaseSelect.vue'
import {
  FillingItemInterface,
  StepThreeInterface,
} from '@/store/modules/room.module'
import FillingItem from '@/modules/MyRoomsPage/components/RoomForm/parts/FillingItem.vue'

import axios from '@/api/axios'

const validations = {
  filling: { required, minLength: minLength(1) },
}

@Component({
  name: 'StepThree',
  components: { FormGroup, Field, BaseSelectNew, FillingItem },
  validations,
})
export default class StepThree extends Vue {
  @Prop({ required: true })
  value!: StepThreeInterface

  @Prop({ required: true })
  isMobile!: boolean

  filling: FillingItemInterface[] = []
  prohibitions: FillingItemInterface[] = []

  /**
   * TODO: Fix adding item in filling list.
   * I'm working ~15h today and my brain is dead now.
   */
  fillingList: FillingItemInterface[] = []

  public deleteItem(index: number): void {
    this.filling.splice(index, 1)
    this.update()
  }

  public async fetchFillingOptions(): Promise<void> {
    try {
      await axios.get('/api/b2b/v1/features').then(({ data }) => {
        this.fillingList = data.data as FillingItemInterface[]
      })
    } catch (e) {
      console.error(e)
    }
  }

  public checkValidity(): boolean {
    this.$v.$touch()

    return !this.$v.$anyError && this.filling.length > 0
  }

  public updateModel(data: StepThreeInterface): void {
    this.filling = data?.filling ?? []
    this.prohibitions = data.prohibitions
  }

  public update(): void {
    this.$emit('input', {
      filling: this.filling,
      prohibitions: this.prohibitions,
    })
  }

  public created(): void {
    this.fetchFillingOptions()
    this.updateModel(this.value)
  }

  public mounted(): void {
    this.fetchFillingOptions()
    this.updateModel(this.value)
  }

  public activated(): void {
    this.fetchFillingOptions()
    this.updateModel(this.value)
  }
}
